import api from '@/services/api/api';

export const useMyHistory = () => {
  const myHistory = useQuery(
    ['myHistory'],
    async () => {
      const { data } = await api.articles.getMyHistory();
      return data;
    },
    {
      staleTime: Infinity,
      select: ({ data }) => data,
    },
  );

  onServerPrefetch(async () => {
    await myHistory.suspense();
  });

  return {
    myHistory,
  };
};
